const PrivacyPolicy = () => {
 
  return (
    <div className="container">
      
    </div>
  );
};

export default PrivacyPolicy;
